import React, { useState, useRef, useEffect } from "react";
import "./App.css";
import Layout from "../Layout";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
  CATEGORIES,
  SEND_VOTE,
  ADVERTISEMENTS,
  PAGES,
  SETTING,
} from "../../store/queries";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import Helmet from "react-helmet";
import { PUBLIC_DIR } from "../../config";
import moment from "../../helpers/moment";
import slug from "slug";
import { CopyToClipboard } from "react-copy-to-clipboard";
function Countdown({ votingEndDate }) {
  // const endTime = moment(new Date("03 11 2020 11:59"));
  // const endTime = moment(new Date("11 March 2023 11:59:59"));
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(moment(new Date()));
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  });

  // var msec = new Date(date).getTime() - new Date(endTime).getTime();
  var date1 = new Date(date).getTime();
  var date2 = new Date(votingEndDate).getTime();
  var msec = date2 - date1;
  var mins = Math.floor(msec / 60000);
  var hrs = Math.floor(mins / 60);
  var days = Math.floor(hrs / 24);
  var sec = Math.floor((msec / 1000) % 60);
  mins = mins % 60;
  hrs = hrs % 24;
  days = days % 365;

  return (
    <div
      className={parseInt(days) <= 3 ? "flashLight" : ""}
      style={{
        ///Todo
        color: parseInt(days) <= 3 ? "red" : "#fff",
        fontSize: 32,
        "font-family": "Exo",
      }}>
      <strong>{days}</strong> Gün <strong>{hrs}</strong> Saat{" "}
      <strong>{mins}</strong> Dakika <strong>{sec}</strong> Saniye
    </div>
  );
}

function VoteButton({ setActive, activeId, item }) {
  return (
    <button
      onClick={(e) => {
        e.preventDefault();
        setActive(item.id);
      }}
      className={`btn ${
        item.id === activeId ? "btn-dark" : "btn-warning"
      } w-100 my-1 text-left py-2 d-flex`}>
      <div className={"mr-2"}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 24,
            height: 24,
            border: "1px solid #ccc",
            borderRadius: 4,
            background: "#fff",
          }}>
          {item.id === activeId && (
            <div
              className={"bg-dark"}
              style={{
                borderRadius: 2,
                width: 14,
                height: 14,
              }}></div>
          )}
        </div>
      </div>
      <div className={"d-flex"}>{item.title}</div>
    </button>
  );
}

function App() {
  const closeModal = useRef();
  const categoryRef = useRef([]);

  const [copied, setCopied] = useState(false);

  const { data, loading, error } = useQuery(CATEGORIES);

  const { pathname, hash } = window.location;

  useEffect(() => {
    if (hash.slice(3) !== "") {
      if (data && data.getCategories) {
        const categoryIndex = data?.getCategories.findIndex(
          (item) => slug(item.title) === hash.slice(3)
        );
        if (categoryIndex >= 0) {
          categoryRef.current[categoryIndex].click();
        } else {
          alert("404 - Aradığınız sayfa bulamadık!");
          window.location = "/#!";
        }
      }
    }
  }, [loading]);

  const {
    data: settingData,
    loading: settingLoading,
    error: settingError,
  } = useQuery(SETTING);

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
  } = useQuery(PAGES);

  const { data: advertisementData, loading: advertisementLoading } =
    useQuery(ADVERTISEMENTS);

  const [sendVote, { loading: sendVoteLoading, error: sendVoteError }] =
    useMutation(SEND_VOTE);

  const [category, setCategory] = useState();
  const [page, setPage] = useState(null);
  const [active, setActive] = useState(null);
  const [itemImage, setItemImage] = useState(null);
  const [loadingVote, setLoadingVote] = useState(false);

  const [message, setMessage] = useState(null);

  useEffect(() => {
    if (category && active) {
      const item = category.subCategories.find(({ id }) => id === active);
      if (item.image) {
        window.$("#exampleModalCenter").animate({ scrollTop: 0 }, "slow");
        setItemImage(item.image);
      } else {
        setItemImage(null);
      }
    }
  }, [active]);

  useEffect(() => {
    setItemImage(null);
    setActive(null);
  }, [category]);

  function renderPage() {
    if (pageLoading) {
      return <div>Yükleniyor..</div>;
    }
    if (pageError) {
      return (
        <h3 className={"w-100 text-center"}>
          Beklenmedik bir hata meydana geldi!
          <br />
          <p>{error}</p>
        </h3>
      );
    }

    if (pageData && pageData.getPages) {
      return (
        <>
          <div
            className="modal fade bd-example-modal-lg pageModal"
            id="modalPage"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle myLargeModalLabel"
            aria-hidden="true">
            <div
              className="modal-dialog modal-dialog-centered modal-lg"
              role="document">
              <div className="modal-content bg-white text-dark">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle">
                    {page && page.title}
                  </h5>
                  <button
                    onClick={() => {
                      setPage(null);
                    }}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: page && page.content,
                    }}></div>
                </div>
              </div>
            </div>
          </div>
          <ul>
            {pageData.getPages.map((item, i) => (
              <li key={i}>
                <a
                  data-toggle="modal"
                  data-target=".pageModal"
                  href={"#"}
                  className={"text-warning"}
                  onClick={(e) => {
                    e.preventDefault();
                    setPage(item);
                  }}>
                  {item.linkTitle}
                </a>
              </li>
            ))}
          </ul>
        </>
      );
    }
  }

  async function onSendVote({ fbId, category, subCategory, fullName }) {
    setMessage(null);
    try {
      const vote = await sendVote({
        variables: {
          data: {
            fbId,
            fullName,
            category,
            subCategory,
          },
        },
      });

      // if (sendVoteError) {
      //   throw new Error(
      //     `Bir hata meydana geldi, ${sendVoteError.message
      //       .replace("GraphQL error:", "")
      //       .trim()}`
      //   );
      // }

      if (vote) {
        window.scrollTo({
          top: 100,
          left: 100,
          behavior: "smooth",
        });
        setMessage({
          type: "success",
          title: "Başarıyla Oy Kullanıldı!",
          content:
            "Ispartanın enlerinin seçildiği anket uygulamasına katıldığınız için teşekkür ederiz.",
        });
      }
    } catch (error) {
      window.scrollTo({
        top: 100,
        left: 100,
        behavior: "smooth",
      });
      setMessage({
        type: "danger",
        title: "Oy Kullanılamadı!",
        content: error.message.replace("GraphQL error:", "").trim(),
      });
    }
    if (closeModal && closeModal.current) closeModal.current.click();
    window.location = "/#!";
    setActive(null);
    setCategory(null);
    setLoadingVote(false);
  }

  function shareOnFB() {
    var url = `https://www.facebook.com/sharer/sharer.php?u=${window.location.toString()}&t=Isparta'nın Enlerinin seçildiği oylamaya sen de katıl!`;
    window.open(
      url,
      "",
      "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600"
    );
    return false;
  }

  // TODO:twitter hesabı var mı onu öğren!
  function shareOntwitter() {
    var url = `http://twitter.com/share?text=Isparta'nın Enlerinin seçildiği oylamaya sen de katıl!&url=${window.location.toString()}`;
    //`https://twitter.com/intent/tweet?url=${window.location.toString().replace("http", "https")}&text=`;
    window.open(
      url,
      "TwitterWindow",
      "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600"
    );

    return false;
  }

  function renderModal() {
    return (
      <div
        className="modal fade bd-example-modal-lg"
        id="exampleModalCenter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle myLargeModalLabel"
        aria-hidden="true">
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document">
          <div className="modal-content bg-white text-dark">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                {category && category.title}
              </h5>
              <button
                ref={closeModal}
                onClick={() => {
                  window.location = "/#!";
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className={"text-center my-3"}>
                <a
                  title="Kategoriyi Facebook'da paylaş!"
                  onClick={() => {
                    shareOnFB();
                  }}
                  target="_blank">
                  <img
                    src={"/facebok.png"}
                    className={"mx-1"}
                    style={{ width: 34 }}></img>
                </a>
                <a
                  title="Kategoriyi Whatsapp'da paylaş!"
                  href={`whatsapp://send?text=Isparta'nın Enlerinin seçildiği oylamaya sen de katıl! ${window.location}`}>
                  <img
                    src={"/whatsapp.png"}
                    className={"mx-1"}
                    style={{ width: 34 }}></img>
                </a>
                <a
                  title="Kategoriyi Twitter'da paylaş!"
                  onClick={() => {
                    shareOntwitter();
                  }}>
                  <img
                    src={"/twitter.png"}
                    className={"mx-1"}
                    style={{ width: 34 }}></img>
                </a>

                <CopyToClipboard
                  text={window.location}
                  onCopy={() => {
                    setCopied(true);
                    setTimeout(() => {
                      setCopied(false);
                    }, 1800);
                  }}>
                  <a
                    title="Kategori adresini kopyala!"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                    }}>
                    <img
                      alt="Kategori adresini kopyala!"
                      src={!copied ? "/url-active.png" : "/url.png"}
                      className={"mx-1"}
                      style={{ width: 34 }}></img>
                  </a>
                </CopyToClipboard>
              </div>
              {copied && (
                <div
                  className="text-center mb-3  font-weight-bold"
                  style={{ fontSize: 13 }}>
                  Kategori linki kopyalandı!
                </div>
              )}
              <ul className={"vote"}>
                <div>
                  {itemImage && (
                    <img
                      src={itemImage}
                      className={"mx-1"}
                      style={{ width: "100%", height: "100%" }}></img>
                  )}
                </div>
                {category &&
                  category.subCategories
                    .sort(function (a, b) {
                      return a.title.localeCompare(b.title);
                    })
                    .map((item, i) => (
                      <VoteButton
                        key={i}
                        setActive={setActive}
                        activeId={active}
                        item={item}
                      />
                    ))}
              </ul>
              <p className={"pt-1 m-0 text-center font-weight-bold"}>
                {category && category.note}
              </p>
              <p className={"m-0 text-center font-weight-bold"}>
                *Adaylar alfabetik sıraya göre sıralanmıştır.
              </p>
              <hr />
              <div className={"text-center"}>
                <button
                  disabled={
                    !active ||
                    loadingVote ||
                    sendVoteLoading ||
                    (settingData &&
                      settingData.getSetting &&
                      !settingData.getSetting.votingStarted)
                  }
                  type={"button"}
                  onClick={(e) => {
                    e.preventDefault();
                    window.checkLoginState((reponse, failure) => {
                      if (failure) {
                        window.scrollTo({
                          top: 100,
                          left: 100,
                          behavior: "smooth",
                        });
                        setMessage({
                          type: "danger",
                          title: "Giriş Yapılamadı!",
                          content:
                            "Facebook ile giriş yapılırken beklenmedik bir hata meydana geldi ve oy kullanılamadı!",
                        });

                        if (closeModal && closeModal.current)
                          closeModal.current.click();
                        setActive(null);
                        setCategory(null);
                        setLoadingVote(false);
                        window.location = "/#!";
                      } else {
                        onSendVote({
                          fbId: reponse.id,
                          category: category.id,
                          subCategory: active,
                          fullName: reponse.name,
                        });
                      }
                    });
                  }}
                  className={`${
                    settingData &&
                    settingData.getSetting &&
                    !settingData.getSetting.votingStarted
                      ? "btn-danger"
                      : "btn-primary btn-facebook"
                  } btn font-weight-bold px-4`}>
                  {settingData &&
                  settingData.getSetting &&
                  !settingData.getSetting.votingStarted
                    ? "Oy Kullanımı Başlamadı"
                    : loadingVote
                    ? "Oy Kullanılıyor..."
                    : "Facebook İle Oy Kullan"}
                </button>

                {/* <FacebookLogin
                  redirectUri={"https://enisparta.com/"}
                  appId="531927667426555"
                  callback={reponse => {
                    
                    
                    onSendVote({
                      fbId: reponse.id,
                      category: category.id,
                      subCategory: active,
                      fullName: reponse.name
                    });
                  }}
                  render={renderProps => (
                    <button
                      disabled={
                        !active ||
                        loadingVote ||
                        sendVoteLoading ||
                        (settingData &&
                          settingData.getSetting &&
                          !settingData.getSetting.votingStarted)
                      }
                      type={"button"}
                      onClick={e => {
                        e.preventDefault();
                        if (active) {
                          setLoadingVote(true);
                          renderProps.onClick();
                        }
                      }}
                      className={`${
                        settingData &&
                        settingData.getSetting &&
                        !settingData.getSetting.votingStarted
                          ? "btn-danger"
                          : "btn-primary btn-facebook"
                      } btn font-weight-bold px-4`}>
                      {settingData &&
                      settingData.getSetting &&
                      !settingData.getSetting.votingStarted
                        ? "Oy Kullanımı Başlamadı"
                        : loadingVote
                        ? "Oy Kullanılıyor..."
                        : "Facebook İle Oy Kullan"}
                    </button>
                  )}
                /> */}
                <br />
                <div className={"mt-3"}>{renderAdvertisement("category")}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function getCategories() {
    if (loading) {
      return <div>Yükleniyor..</div>;
    }
    if (error) {
      return (
        <h3 className={"w-100 text-center"}>
          Beklenmedik bir hata meydana geldi!
          <br />
          <p>{error}</p>
        </h3>
      );
    }

    if (data && data.getCategories) {
      return data.getCategories.map((item, i) => (
        <div key={i} className={"col-md-4 m-0 px-1 d-flex"}>
          <button
            ref={(el) => (categoryRef.current[i] = el)}
            onClick={() => {
              setCategory(item);
              window.location = `#!/${slug(item.title)}`;
              //TODO
              // console.log(slug(item.title), item);
            }}
            data-toggle="modal"
            data-target=".bd-example-modal-lg"
            type={"button"}
            className={"m-1 btn-lg btn btn-warning w-100 "}>
            {item.title}
          </button>
        </div>
      ));
    }
  }

  function renderAdvertisement(location) {
    function renderAdvertisementLink({ link, content }) {
      if (link !== "") {
        return (
          <a target="_blank" href={link}>
            <div
              dangerouslySetInnerHTML={{
                __html: content,
              }}></div>
          </a>
        );
      } else {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: content,
            }}></div>
        );
      }
    }

    if (advertisementLoading) {
      return "Reklam Yükleniyor...";
    }

    if (advertisementData && advertisementData.getAdvertisements) {
      const advertisements = advertisementData.getAdvertisements.filter(
        (item) => item.location === location
      );
      if (advertisements.length > 0) {
        const { link, content } = advertisements[0];

        if (
          location === "category" ||
          location === "footer" ||
          location === "header"
        ) {
          return (
            <div className={"w-100 text-center"} style={{ overflow: "hidden" }}>
              {renderAdvertisementLink({ link, content })}
            </div>
          );
        }
        if (location === "left" || location === "right") {
          return (
            <div
              className={`d-none d-lg-block text-${
                location === "left" ? "right" : "left"
              }`}
              style={{ overflow: "hidden", minHeight: 500 }}>
              {renderAdvertisementLink({ link, content })}
            </div>
          );
        }
      } else {
        return null;
      }
    }
  }

  function renderHeader() {
    if (!settingLoading) {
      if (!settingError) {
        if (settingData && settingData.getSetting) {
          return (
            <Helmet>
              <title>{settingData.getSetting.title}</title>
              <meta
                name="description"
                content={settingData.getSetting.description}
              />
              <meta name="keywords" content={settingData.getSetting.keywords} />
            </Helmet>
          );
        }
      }
    }
  }
  return (
    <Layout>
      {renderHeader()}
      {renderModal()}
      <div className={"container"}>
        <div className={"row my-3"}>
          <div className={"col-md-12 text-center"}>
            <a href="/#!">
              <img
                src={PUBLIC_DIR(
                  settingData &&
                    settingData.getSetting &&
                    settingData.getSetting.logo
                )}
                className={"img-fluid"}
                title={
                  settingData &&
                  settingData.getSetting &&
                  settingData.getSetting.title
                }
              />
            </a>
            {settingData &&
              settingData.getSetting &&
              settingData.getSetting.votingStarted && (
                <Countdown
                  votingEndDate={settingData?.getSetting?.votingEndDate}
                />
              )}
          </div>
        </div>
        {/*Header Reklam Alanı */}
        <div className={"row"}>
          <div className={"col-md-12"}>{renderAdvertisement("header")}</div>
        </div>
        <div className={"row"}>
          <div className={"col-md-12"}>
            {message && (
              <div
                className={`alert alert-${message.type} alert-dismissible fade show`}
                role="alert">
                <strong>{message.title}</strong> {message.content}
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={"container-fluid"}>
        <div className={"row mb-5"}>
          <div className={"col-md-2"}>{renderAdvertisement("left")}</div>
          <div className={"col-md-8"}>
            <div className={"row"}>{getCategories()}</div>
            <div className={"row my-5"}>
              <div className={"col-md-12"}>{renderAdvertisement("footer")}</div>
            </div>
          </div>
          <div className={"col-md-2"}>{renderAdvertisement("right")}</div>
        </div>
      </div>
      <div className={"container"}>
        {pageData && pageData.getPages && pageData.getPages.length > 0 && (
          <>
            <footer
              className={
                "row border border-warning border-left-0 border-right-0 py-2 mb-5"
              }>
              <div className={"col-md-9 text-left"}>{renderPage()}</div>
              <div className={"col-md-3 text-right"}>
                Bu bir{" "}
                <a
                  href="http://ajans32.com"
                  target="_blank"
                  className={"text-warning"}>
                  Ajans32
                </a>{" "}
                projesidir.
              </div>
            </footer>
            <div
              className={"text-center mb-3"}
              style={{ color: "#ccc", opacity: 0.6 }}>
              Bu bir{" "}
              <a
                className={"text-warning"}
                href="http://swbilisim.com"
                target={"_blank"}>
                SW Bilişim
              </a>{" "}
              ürünüdür. <br />
              <small style={{ color: "#999" }}>
                Yazılım ve Barındırma Hizmetleri SW Bilişim.
              </small>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
}

export default App;
