export const URL = "https://enisparta.com/";
// export const URL = "http://localhost:5000/";
const endPoint = "graphql";

export const API_URL = `${URL}${endPoint}`;

export const PUBLIC_DIR = (path = "") => {
  return `${URL + path}`;
};

export const API = (params) => `${URL + endPoint}?${params}`;
export const API_POST = `${URL + endPoint}`;
export const PUBLIC_URL = (url) => `${URL}${url.substr(9)}`;
