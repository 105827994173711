import { gql } from "apollo-boost";

export const SETTING = gql`
  {
    getSetting {
      title
      keywords
      description
      logo
      votingStarted
      votingEndDate
    }
  }
`;

export const PAGES = gql`
  {
    getPages {
      id
      linkTitle
      title
      content
      orders
      active
    }
  }
`;

export const ADVERTISEMENTS = gql`
  {
    getAdvertisements {
      id
      title
      link
      location
      content
      active
    }
  }
`;

export const CATEGORIES = gql`
  {
    getCategories {
      id
      title
      note
      orders
      active
      subCategories {
        id
        title
        image
        active
      }
    }
  }
`;

export const SEND_VOTE = gql`
  mutation ($data: sendVoteInput) {
    sendVote(data: $data) {
      id
    }
  }
`;

// const CATEGORY_FRAGMENT = gql`
//   fragment CategoryFragment on Category {
//     id
//     title
//     orders
//     active
//     subCategories {
//       id
//       title
//       active
//     }
//   }
// `;

// const SUB_CATEGORY_FRAGMENT = gql`
//   fragment SubCategoryFragment on SubCategory {
//     id
//     title
//     active
//   }
// `;

// export const SUB_CATEGORY = {
//   data: gql`
//     query($parentCategoryId: ID!) {
//       subCategories(parentCategoryId: $parentCategoryId) {
//         ...SubCategoryFragment
//       }
//     }
//     ${SUB_CATEGORY_FRAGMENT}
//   `,
//   item: gql`
//     query($id: ID!, $parentCategoryId: ID!) {
//       subCategory(id: $id, parentCategoryId: $parentCategoryId) {
//         ...SubCategoryFragment
//       }
//     }
//     ${SUB_CATEGORY_FRAGMENT}
//   `,
//   update: gql`
//     mutation($data: updateSubCategoryInput!) {
//       updateSubCategory(data: $data) {
//         ...SubCategoryFragment
//       }
//     }
//     ${SUB_CATEGORY_FRAGMENT}
//   `,
//   create: gql`
//     mutation($data: createSubCategoryInput!) {
//       createSubCategory(data: $data) {
//         ...SubCategoryFragment
//       }
//     }
//     ${SUB_CATEGORY_FRAGMENT}
//   `,
//   remove: gql`
//     mutation($id: ID!, $parentCategoryId: ID!) {
//       removeSubCategory(parentCategoryId: $parentCategoryId, id: $id)
//     }
//   `
// };

// export const CATEGORY = {
//   data: gql`
//     {
//       categories {
//         ...CategoryFragment
//       }
//     }
//     ${CATEGORY_FRAGMENT}
//   `,
//   item: gql`
//     query($id: ID!) {
//       category(id: $id) {
//         ...CategoryFragment
//       }
//     }
//     ${CATEGORY_FRAGMENT}
//   `,
//   update: gql`
//     mutation($data: updateCategoryInput!) {
//       updateCategory(data: $data) {
//         ...CategoryFragment
//       }
//     }
//     ${CATEGORY_FRAGMENT}
//   `,
//   create: gql`
//     mutation($data: createCategoryInput!) {
//       createCategory(data: $data) {
//         ...CategoryFragment
//       }
//     }
//     ${CATEGORY_FRAGMENT}
//   `,
//   remove: gql`
//     mutation($id: ID!) {
//       removeCategory(id: $id)
//     }
//   `
// };

// export const GET_ME = gql`
//   {
//     me {
//       fullName
//       username
//     }
//   }
// `;

// export const LOGIN = gql`
//   mutation($data: loginUserInput) {
//     login(data: $data) {
//       token
//     }
//   }
// `;
